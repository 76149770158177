<template>
  <v-card elevation="0">
    <v-card-text>
      <v-form>
        <v-combobox
          v-model="searchValues.themes"
          :items="themes"
          search-input.sync="themeSearch"
          multiple
          chips
          small-chips
          deletable-chips
          clearable
        ></v-combobox>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text>Reset</v-btn>
      <v-btn color="secondary" depressed>Apply</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      themeSearch: null,
      searchValues: {},
    };
  },
  computed: {
    themes() {
      return [
        "",
        "Horror",
        "Science",
        "Ameliorated",
        "Attitude",
        "Bandwidth-monitored",
        "Coherent",
        "Customer-focused",
        "Customizable",
        "Down-sized",
        "Firmware",
        "Focused",
        "Full-range",
        "Horizontal",
        "Internet solution",
        "Knowledge user",
        "Object-based",
        "Optimal",
        "Policy",
        "Public-key",
        "Stand-alone",
        "Superstructure",
        "Time-frame",
        "Toolset",
        "Universal",
        "User-centric",
        "User-facing",
        "Well-modulated",
      ];
    },
  },
  watch: {},
  methods: {},
};
</script>